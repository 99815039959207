.root {
}

.selectError {
  border: var(--borderErrorField);
}

.labelWrapper{
  display: flex;
  align-items: baseline;
  gap: 4px;
  position: relative;
}