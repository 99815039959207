@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: #000;
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--colorSuccess);
  }

  /* disabled version */
  &:hover + label .boxDisabled,
  &:focus + label .boxDisabled,
  &:checked + label .boxDisabled {
    stroke: var(--colorGrey300);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: #000;
    stroke-width: 1px;
  }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--colorSuccess);
    stroke-width: 1px;
  }

  /* Display the disabled when checked */
  &:checked + label .checkedDisabled {
    display: inline;
    stroke: var(--colorGrey300);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: #4A4A4A;
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 0;
}

.checkboxWrapper {
  height: 32px;
  margin-top: -2px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  display: none;
  @media (--viewportMedium) {
    height: 24px;
    margin-top: -1px;
  }
}

.checked {
  display: none;
  fill: #000;
}
.checkedSuccess {
  display: none;
  fill: var(--colorSuccess);
}

.checkedDisabled {
  display: none;
  fill: var(--colorGrey300);
}

.boxDisabled,
.boxSuccess,
.box {
  stroke: var(--colorGrey300);
}

.text {
}
.textRoot {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--colorGrey700);
  padding: 0;
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
input[type=checkbox] + label {
  display: block;
  cursor: pointer;
  color: #4A4A4A;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.305px;
}


input[type=checkbox] {
  display: none;
}


input[type=checkbox] + label:before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_2295_1744)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3643 3.40733C14.5518 3.59486 14.6571 3.84917 14.6571 4.11433C14.6571 4.3795 14.5518 4.63381 14.3643 4.82133L6.86901 12.3167C6.76996 12.4157 6.65236 12.4943 6.52293 12.548C6.3935 12.6016 6.25477 12.6292 6.11468 12.6292C5.97458 12.6292 5.83585 12.6016 5.70642 12.548C5.57699 12.4943 5.4594 12.4157 5.36034 12.3167L1.63634 8.59333C1.54083 8.50109 1.46465 8.39074 1.41224 8.26874C1.35983 8.14673 1.33225 8.01551 1.33109 7.88273C1.32994 7.74995 1.35524 7.61828 1.40552 7.49538C1.4558 7.37248 1.53006 7.26083 1.62395 7.16694C1.71784 7.07305 1.82949 6.99879 1.95239 6.94851C2.07529 6.89823 2.20696 6.87293 2.33974 6.87408C2.47252 6.87524 2.60374 6.90282 2.72575 6.95523C2.84775 7.00764 2.9581 7.08382 3.05034 7.17933L6.11434 10.2433L12.9497 3.40733C13.0425 3.31441 13.1528 3.24069 13.2742 3.1904C13.3955 3.1401 13.5256 3.11421 13.657 3.11421C13.7884 3.11421 13.9185 3.1401 14.0398 3.1904C14.1612 3.24069 14.2715 3.31441 14.3643 3.40733Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2295_1744'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
  background-position: 3px;
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 22px;
  height: 22px;
  padding-left: 5px;
  padding-top: 2px;
  margin-right: 10px;
  vertical-align: bottom;
  color: transparent;
  transition: .2s;
}


input[type=checkbox] + label:active:before {
  transform: scale(0);
}


input[type=checkbox]:checked + label:before {
  background-color: #C8814D;
  border-color: #C8814D;
  color: white;
}


input[type=checkbox]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}


input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #F7C28F;
  border-color: #F7C28F;
}
