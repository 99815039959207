@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewContent {
  composes: textSmall from global;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
  font-family: Poppins;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.34823527932167053px;
  text-align: left;
  color: #4A4A4A;
  padding: 0;
  &::first-letter{
    text-transform: capitalize;
  }
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: #6E6E73;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: none;

  @media (--viewportMedium) {
    display: inline-flex;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}
