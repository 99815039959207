.jobsCardWrapper {
  max-width: 1534px;
  margin: 0 auto;
  padding: 0 30px;
  & .joinButton {
    color: #fff;
    text-align: center;
    font-family: 'Lato';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 277.778% */
    letter-spacing: 1px;
    background-color: #c7814d;
    height: 58px;
    width: 257px;
    border-radius: 0;
    margin: 69px auto 0;
    @media (max-width: 767px) {
      font-size: 14px;
      width: 220px;
      height: 44px;
    }
  }
  & .jobCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 46px;
    @media (max-width: 1450px) {
      gap: 32px;
    }
    @media (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
    @media (max-width: 680px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }
    & .jobCard {
      background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
      box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.14);
      position: relative;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      &:hover {
        text-decoration: unset;
      }
      & .cardImage {
        position: relative;
        width: 100%;
        & .cardImageBox {
          padding-top: 47.5%;
          border: 1px solid #80808030;
        }
        & img, & .noIcon {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          & svg{
            width: 60%;
            height: 60%;
          }
        }
      }
      & .cardContents {
        padding: 34px 30px 21px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1;
        & .title {
          color: #2c2c2c;
          font-family: 'Lato';
          font-size: 22px;
          font-style: normal;
          font-weight: 800;
          line-height: 26px; /* 118.182% */
          letter-spacing: 1px;
          margin: 0;
          padding: 0;
        }
        & .description {
          color: #2c2c2c;
          font-family: 'Lato';
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */
          letter-spacing: 1px;
          padding: 0;
          margin: 20px 0;
          /* min-height: 60px; */
        }
        & .skillContents {
          display: flex;
          gap: 9px;
          flex-wrap: wrap;
          & .skillContent {
            padding: 4px 20px;
            color: #2c2c2c;
            font-family: 'Lato';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            letter-spacing: 1px;
            text-transform: uppercase;
            border-radius: 100px;
            border: 2px solid var(--Pebble, #f7f6f5);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        & .dividerLine {
          background: #bebaba;
          height: 1px;
          width: 100%;
          position: relative;
          margin: 23px 0 18px;
          &:before {
            content: '';
            height: 7px;
            width: 7px;
            background: #bebaba;
            border-radius: 100px;
            display: block;
            position: absolute;
            top: -3px;
          }
          &:after {
            content: '';
            height: 7px;
            width: 7px;
            background: #bebaba;
            border-radius: 100px;
            display: block;
            position: absolute;
            top: -3px;
            right: 0;
          }
        }
        & h6 {
          color: #2c2c2c;
          text-align: center;
          font-family: 'Lato';
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 133.333% */
          letter-spacing: 2px;
          margin: 0;
          padding: 0;
        }
      }
      & .categoryTitle {
        position: absolute;
        top: 0;
        left: 0;
        height: 38px;
        padding: 0 18px;
        background: #2c2c2c;
        color: #fff;
        font-family: 'Lato';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.readStatus {
  cursor: pointer;
}

.link {
  &:hover {
    text-decoration: none;
  }
}

.blurCard{
  filter: blur(8px);
}