@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;
  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.PublicImage {
  max-height: 158px;
  & img {
    object-fit: cover;
  }
}

.publicListing {
  & .info {
    padding: 34px 30px 21px;
  }
  & .title {
    color: #2c2c2c;
    font-family: 'Lato';
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 26px;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
  }
  & .skillContents {
    display: flex;
    gap: 9px;
    flex-wrap: wrap;
    & .skillContent {
      padding: 4px 20px;
      color: #2c2c2c;
      font-family: 'Lato';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1px;
      text-transform: uppercase;
      border-radius: 100px;
      border: 2px solid var(--Pebble, #e4e1df);
    }
  }
  & .dividerLine {
    background: #bebaba;
    height: 1px;
    width: 100%;
    position: relative;
    margin: 23px 0 18px;
    &:before {
      content: '';
      height: 7px;
      width: 7px;
      background: #bebaba;
      border-radius: 100px;
      display: block;
      position: absolute;
      top: -3px;
    }
    &:after {
      content: '';
      height: 7px;
      width: 7px;
      background: #bebaba;
      border-radius: 100px;
      display: block;
      position: absolute;
      top: -3px;
      right: 0;
    }
  }
  & h6 {
    color: #2c2c2c;
    text-align: center;
    font-family: 'Lato';
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 133.333% */
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
  }
  & .categoryTitle {
    position: absolute;
    top: 0;
    left: 0;
    height: 38px;
    width: 140px;
    background: #2c2c2c;
    color: #fff;
    font-family: 'Lato';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.description {
  color: #2c2c2c;
  font-family: 'Lato';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1px;
  padding: 0;
  margin: 20px 0;
}
