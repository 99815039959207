.professionalCardWrapper {
  max-width: 1534px;
  margin: 0 auto;
  padding: 0 30px;
  & .joinButton {
    color: #fff;
    text-align: center;
    font-family: 'Lato';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 277.778% */
    letter-spacing: 1px;
    background-color: #c7814d;
    height: 58px;
    width: 257px;
    border-radius: 0;
    margin: 69px auto 0;
    @media (max-width: 767px) {
      font-size: 14px;
      width: 220px;
      height: 44px;
    }
  }
  & .professionalCards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 46px;
    @media (max-width: 1450px) {
      gap: 32px;
    }
    @media (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 1100px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
    @media (max-width: 680px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }
    & .professionalCard {
      background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
      box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.14);
      position: relative;
      &:hover {
        text-decoration: unset;
      }
      & .cardImage {
        position: relative;
        height: 82px;
        width: 82px;
        border-radius: 100px;
        @media (max-width: 767px) {
          height: 64px;
          width: 64px;
        }
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      & .cardContents {
        padding: 26px 24px 22px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & a{
          &:hover{
            text-decoration: none;
          }
        }
        & .mainContent {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        & .nameText {
          color: #000;
          font-family: 'Lato';
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 377.778% */
          letter-spacing: 1px;
          margin: 0;
          padding: 0;
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
        & .professionText {
          color: #000;
          font-family: 'Lato';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 523.077% */
          letter-spacing: 1px;
          text-transform: uppercase;
          text-align: left;
          margin: 1px 0 3px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          @media (max-width: 767px) {
            font-size: 11px;
          }
        }
        & .starIcons {
          & svg {
            width: 12px;
            height: 12px;
            margin-right: 2px;
          }
        }
        & .description {
          color: #2c2c2c;
          font-family: 'Lato';
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 20px; /* 142.857% */
          letter-spacing: 1px;
          padding: 0;
          /* height: 57px; */
          margin: 20px 0;
        }
        & .skillContents {
          & .skillContent {
            display: flex;
            gap: 9px;
            flex-wrap: wrap;
            color: #2c2c2c;
            font-family: 'Lato';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 142.857% */
            letter-spacing: 1px;
            text-transform: uppercase;
            @media (max-width: 767px) {
              font-size: 12px;
              padding: 2px 18px;
            }
            &:first-child {
              margin-bottom: 10px;
            }
            & .skillContentInner {
              padding: 4px 24px;
              border-radius: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: calc((100% - 9px) / 2);
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              border: 2px solid var(--Pebble, #f7f6f5);
            }
          }
        }
        & .viewButton {
          background-color: transparent;
          color: #000;
          text-align: center;
          font-family: 'Lato';
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 50px; /* 277.778% */
          letter-spacing: 1px;
          border: 1px solid #000;
          margin-top: 26px;
          min-height: 48px;
          @media (max-width: 767px) {
            font-size: 14px;
            padding: 2px 18px;
            min-height: 36px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
.blurCard {
  filter: blur(4px);
}

.bottomBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.blurCard{
  filter: blur(8px);
}