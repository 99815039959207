.root {
}

.select {
  color: var(--matterColorAnti);
  border: solid 1px var(--matterColorNegative);
  padding: 0px 15px;
  border-radius: 10px;
  height: 45px;
  padding-right: 40px;

  & > option {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

.multiSelectBox {
  /* & :global(#react-select-3-listbox) {
    max-height: 600px !important;
    overflow: hidden;
    position: relative;
    z-index: 1000;
    padding-bottom: 30px;
    & div {
      max-height: 1000px !important;
    }
  } */
  & > div:nth-child(3) {
    & > div:nth-child(1) {
      display: flex !important;
      & > div {
        margin: 6px 9px 6px 0;
        & > div:nth-child(2) {
          margin-bottom: 0px !important;
          /* margin-top: 6px; */
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      & > div:nth-child(2) {
        /* width: 100%;
          order: 0;
          display: block;
          margin-bottom: 8px;
          padding: 0 1px; */
        &:before {
          content: '';
          position: absolute;
          right: 14px;
          top: 25px;
          background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.68579 5.31455C4.86 5.48787 5.14 5.48787 5.31421 5.31455L8.86967 1.75909C9.04344 1.58487 9.04344 1.30399 8.86967 1.13066C8.6959 0.956446 8.41457 0.956446 8.24124 1.13066L5 4.37146L1.75876 1.13066C1.58498 0.956446 1.30366 0.956446 1.13033 1.13066C0.956557 1.30399 0.956557 1.58487 1.13033 1.75909L4.68579 5.31455Z' fill='%233D4047' stroke='%233D4047'/%3E%3C/svg%3E%0A");
          width: 14px;
          height: 14px;
          background-size: 9px;
        }
        & input {
          border-radius: 4px;
          border: 1px solid #d8dce6 !important;
          height: 40px;
          padding: 4px 8px !important;
          min-width: 100% !important;
          opacity: 1 !important;
        }
        &::after {
          content: unset;
        }
      }
    }
    & > div:nth-child(2) {
      display: none !important;
    }
  }

  & :global(#react-select-2-placeholder),
  & :global(#react-select-3-placeholder),
  & :global(#react-select-4-placeholder),
  & :global(#react-select-5-placeholder),
  & :global(#react-select-6-placeholder),
  & :global(#react-select-7-placeholder),
  & :global(#react-select-8-placeholder),
  & :global(#react-select-9-placeholder),
  & :global(#react-select-10-placeholder),
  & :global(#react-select-11-placeholder),
  & :global(#react-select-12-placeholder),
  & :global(#react-select-13-placeholder),
  & :global(#react-select-14-placeholder),
  & :global(#react-select-15-placeholder),
  & :global(#react-select-16-placeholder),
  & :global(#react-select-17-placeholder),
  & :global(#react-select-18-placeholder),
  & :global(#react-select-19-placeholder),
  & :global(#react-select-20-placeholder) {
    /* display: none !important; */
    margin: 5px 0px;
    position: absolute;
    top: 12px;
    border: none !important;
    left: 12px;
    padding-left: 0 !important;
  }

  & :global(.css-qbdosj-Input) {
  }
  & > div {
    /* background: #FFFFFF; */
    border-radius: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    border-color: transparent;
    color: #525961;
    /* box-shadow: none !important; */

    /* border-color: rgb(82 89 97 / 80%); */
    &:hover {
      border-color: transparent;
    }

    &:first-of-type {
      box-shadow: none !important;
    }

    &:not(:last-child) {
      min-height: auto;
    }

    & > div {
      padding: 0px;
      flex-direction: row-reverse;
      justify-content: start;
      flex-wrap: wrap-reverse;
      padding: 0 1px;
      & :last-child {
        width: 100%;
        order: 0;
        display: block;
        /* margin-bottom: 8px; */
        & input {
          border-radius: 4px;
          border: 1px solid #d8dce6 !important;
          height: 40px;
          padding: 4px 8px !important;
          min-width: 100% !important;
        }
        &::after {
          content: unset;
        }
      }

      &::-webkit-scrollbar {
        height: 2px;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #e6e6e6;
      }

      &::-webkit-scrollbar-thumb {
        background: #9b9b9b;
      }
    }

    & input {
      height: auto;
    }
  }

  & > span + div {
    & > div:first-child {
      & > div:not(:last-child) {
        width: max-content;
        display: inline-flex;
        align-items: center;
        gap: 6px;
        padding: 0px 8px 0px 16px;
        font-size: 15px;
        border-radius: 100px;
        border: 1px solid #c6c6c6;
        background: #fff;
        @media (max-width: 370px) {
          font-size: 12px;
        }

        & div[role='button'] {
          border-radius: 50%;
          height: 24px;
          width: 24px;
          & svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}

.parent {
  display: flex;
  border-bottom: 1px solid;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 14px;
  height: 18px;

  & input {
    width: 15px;
    height: 15px;
  }

  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #445463;
    white-space: nowrap;
    margin: 0;
    padding: 0;
  }

  & input[type='checkbox'] {
    accent-color: #2e3599;
  }
}
