@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: unset !important;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.15);
  padding: 38px 55px 49px 48px;
  padding: 24px;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    background-color: var(--colorWhite);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
    padding: 38px 55px 49px 48px;
  }

  & textarea {
    height: 91px !important;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 0px;
  padding: 0px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  /* Font */
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  margin: 0 0 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.author {
  composes: p from global;
  margin: 0px 0 21px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;

  @media (--viewportMedium) {
    width: 100%;
    margin: 0px 0 21px 0;
    color: var(--colorGrey700);
  }
}

.providerAvatar {
  /* display: none; */
  width: 39px;
  height: 39px;
  margin-right: 7px;

  @media (--viewportLarge) {
    display: flex;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.3047058880329132px;
  text-align: left;
  color: #4A4A4A;

  @media (--viewportLarge) {
    display: inline;
  }

  & a {
    color: #4A4A4A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.305px;
  }
}

.budgetText {
  color: var(--Charcoal, #141313);
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 228.571% */
  margin: 9px 0 12px;
}

.orderHeading {
  color: var(--Charcoal, #4b4745);
  font-family: "Lato";
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 139.13% */
  letter-spacing: -0.75px;
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }

  & .headingBold {
    font-weight: 700;

    & h1 {
      display: inline;
      color: var(--Charcoal, #4b4745);
      font-family: "Lato";
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      /* 139.13% */
      letter-spacing: -0.75px;
    }
  }
}

.orderHeadingNote {
  margin: 0;
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--colorGrey700);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 4px;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 3px 0;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    margin-right: unset;
    padding: 0;
  }
}

.priceContainerInCTA {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: unset;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--marketplaceColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {}
}

.closedListingButton {
  border-left: 1px solid var(--colorGrey100);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

/*  */
.custom_design {
  text-align: center;
}

.custom_title {
  font-size: 24px;
  font-weight: bold;
}

.custom_line {
  width: 100%;
  border-bottom: 1px solid #000;
  margin: 15px 2px;
}

.custom_description {
  font-size: 18px;
  margin-bottom: 20px;
}

.custom_button {
  display: block;
  width: 351px;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.txtNone {
  text-decoration: none !important;
}