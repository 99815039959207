@import '../../styles/customMediaQueries.css';

.link {
  & .imageWrapper {
    height: 232px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    position: relative;
    @media (--viewportMedium) {
      height: 204px;
    }
    & .onproduct {
      position: absolute;
      top: 10px;
      right: 12px;
      margin: 0;
      padding: 4px 10px;
      font-size: 16px;
      color: #fff;
      background: #b06b5d;
      border-radius: 45px;
    }
  }
}
.link {
  &:hover {
    text-decoration: unset;
  }
}
.openListingButton {
  background-color: #dfeafd;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4472b7;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 123.077% */
  letter-spacing: -0.2px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}

.category {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: flex-start;
  padding: 0;

  margin-top: 20px;
  margin-bottom: 0px;
  min-height: 80px;
  & > span {
    border-radius: 20px;
    color: #4472b7;
    font-family: Poppins;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
    color: #2c2c2c;
    padding: 6px 22px;
    border: 2px solid #e4e1df;
    max-width: calc((100% - 8px) / 2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.root {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.cardFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 3px;
  flex: 1;
  @media (--viewportMedium) {
  }
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  height: 100%;
}

.linkbuyNow {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 2px;
  &:hover {
    text-decoration: none;
  }
}

.emptySpace {
  flex: 1;
}

.likeIcon {
  border-radius: 9999px;
  /* background: var(--Sand---Primary, #efe4d3); */
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  cursor: pointer;
  left: 10px;
}

.priceWrapper {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  letter-spacing: 2px;
  white-space: pre;
}
.readStatus {
  cursor: pointer;
}
.bagButton {
  /* width: 73.09px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--Sand---Primary, #efe4d3);
  color: var(--Black, #000);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  height: 26px;
  @media (--viewportLarge) {
    height: 33px;
  }
  & > span {
    padding: 2px 9.7px 2px 10px;
    @media (--viewportLarge) {
      padding: 4px 9.7px 4px 10px;
    }
    cursor: pointer;
    gap: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid;
  border-color: #fff #fff;
  animation: l1 1s infinite;
}
@keyframes l1 {
  to {
    transform: rotate(0.5turn);
  }
}

.modalRemove {
  & > div {
    & > div {
      flex-basis: 448px !important;
      & > button {
        display: none !important;
      }
    }
  }
}

.deleteIcon {
  height: 72px;
  width: 72px;
  fill: #000;
}

.modalContent {
  text-align: center;

  & .modalTitle {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    line-height: 1.75rem;
    font-size: 1.25rem;
    font-weight: 700;
  }
  & .modalText {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
  }

  & .buttonGroups {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-top: 40px;
  }
  & .cancelBtn {
    width: 8rem;
    min-height: 40px;
    border: 1px solid #000;
    background-color: #fff;
    color: #000;
    border-radius: 50px;
    font-weight: 400;
  }
  & .removeBtn {
    width: 8rem;
    min-height: 40px;
    border-radius: 50px;
    font-weight: 400;
  }
}

.desktopText {
  @media (max-width: 767px) {
    display: none;
  }
}
.jobCard {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover {
    text-decoration: unset;
  }
  & .cardImage {
    position: relative;
    width: 100%;
    padding-top: 47.5%;
    border: 1px solid #80808030;
    & img, & .noIcon {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg{
        width: 60%;
        height: 60%;
      }
    }
  }
  & .circleImage {
    position: relative;
    width: 30%;
    padding-top: 47.5%;
    & img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      top: 10px;
      right: 10px;
      object-fit: cover;
    }
  }
  & .cardContents {
    padding: 34px 30px 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    & .title {
      color: #2c2c2c;
      font-family: 'Lato';
      font-size: 22px;
      font-style: normal;
      font-weight: 800;
      line-height: 26px; /* 118.182% */
      letter-spacing: 1px;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    & .description {
      color: #2c2c2c;
      font-family: 'Lato';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1px;
      padding: 0;
      margin: 20px 0;
      /* min-height: 60px; */
    }
    & .skillContents {
      display: flex;
      gap: 9px;
      flex-wrap: wrap;
      & .skillContent {
        padding: 4px 20px;
        color: #2c2c2c;
        font-family: 'Lato';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 1px;
        text-transform: uppercase;
        border-radius: 100px;
        border: 2px solid var(--Pebble, #f7f6f5);
      }
    }
    & .dividerLine {
      background: #bebaba;
      height: 1px;
      width: 100%;
      position: relative;
      margin: 23px 0 18px;
      &:before {
        content: '';
        height: 7px;
        width: 7px;
        background: #bebaba;
        border-radius: 100px;
        display: block;
        position: absolute;
        top: -3px;
      }
      &:after {
        content: '';
        height: 7px;
        width: 7px;
        background: #bebaba;
        border-radius: 100px;
        display: block;
        position: absolute;
        top: -3px;
        right: 0;
      }
    }
    & h6 {
      color: #2c2c2c;
      text-align: center;
      font-family: 'Lato';
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 133.333% */
      letter-spacing: 2px;
      margin: 0;
      padding: 0;
    }
  }
  & .categoryTitle {
    position: absolute;
    top: 0;
    left: 0;
    height: 38px;
    padding: 0 24px;
    background: #2c2c2c;
    color: #fff;
    font-family: 'Lato';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.professionalProfileInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 26px 24px 17px;
  & .image {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    @media (max-width: 767px) {
      width: 72px;
      height: 72px;
    }
    & .cardImage {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      overflow: hidden;
      padding-top: 0;
      background-color: #ddd;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        width: 72px;
        height: 72px;
      }
    }
  }
  & .title {
    color: #000;
    font-family: 'Lato';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 377.778% */
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    color: #000000;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & .professionText {
    color: #000;
    font-family: 'Lato';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 523.077% */
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: left;
    margin: 1px 0 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    @media (max-width: 767px) {
      font-size: 11px;
    }
  }
  & .starIcons {
    & svg {
      width: 12px;
      height: 12px;
      margin-right: 2px;
    }
  }
}

.noImageIcon {
  padding-top: 0px;
  box-sizing: content-box;
  stroke: var(--colorGrey700);
}

.footerData {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.professionalContents {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
  & .description {
    padding: 0;
    font-family: Lato;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
    color: #000000;
    margin: 0;
    min-height: 60px;
  }
}

.dividerLine {
  background: #bebaba;
  height: 1px;
  width: 100%;
  position: relative;
  margin: 23px 0 18px;
  margin-top: auto;
  &:before {
    content: '';
    height: 7px;
    width: 7px;
    background: #bebaba;
    border-radius: 100px;
    display: block;
    position: absolute;
    top: -3px;
  }
  &:after {
    content: '';
    height: 7px;
    width: 7px;
    background: #bebaba;
    border-radius: 100px;
    display: block;
    position: absolute;
    top: -3px;
    right: 0;
  }
}

.viewBtn {
  background-color: transparent;
  color: #000;
  text-align: center;
  font-family: 'Lato';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: 1px;
  border: 1px solid #000;
  margin-top: 26px;
  min-height: 48px;
  margin-bottom: 22px;
  &:hover {
    background-color: #fff;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    padding: 2px 18px;
    min-height: 36px;
    line-height: 30px;
  }
}
