@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBottomWrapperText from global;
  padding-bottom: 6px;
  margin-top: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
  & label{
    &:before{
      content: unset !important;
    }
  }
}

.finePrint {
  composes: marketplaceModalHelperLink from global;
  padding: 3px 0 5px 0;
  text-align: left;
  color: #4A4A4A;
text-align: center;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 123.077% */
letter-spacing: -0.2px;
}

.privacyLink,
.termsLink {
  composes: marketplaceModalHelperLink from global;
  color: #C35F31;
text-align: center;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 123.077% */
letter-spacing: -0.2px;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
